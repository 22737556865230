import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const BlogRoll = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const [blogPostList, setBlogPostList] = useState([...edges.slice(0, 6)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(edges.length > 6);
  const loadRef = useRef();

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = blogPostList.length;
      const isMore = currentLength < edges.length;
      const nextResults = isMore
        ? edges.slice(currentLength, currentLength + 6)
        : [];
      setBlogPostList([...blogPostList, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  useEffect(() => {
    const isMore = blogPostList.length < edges.length;
    setHasMore(isMore);
  }, [blogPostList]); //eslint-disable-line

  return (
    <Fragment>
      <div className="card-columns-wrapper">
        {blogPostList &&
          blogPostList.map(post => (
            <div key={post.node.id} className="mb-5">
              <div className="card card-post" id="card-post___list">
                {post.node.frontmatter.featuredImage ? (
                  <div className="featured-thumbnail">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.node.frontmatter.featuredImage,
                        alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                      }}
                    />
                  </div>
                ) : null}
                <div className="card-body card___content">
                  <h5 className="card-title title">
                    {post.node.frontmatter.title}
                  </h5>
                  <p className="card-text post-card-excerpt">
                    {post.node.excerpt}
                  </p>
                  <Link className="button" to={post.node.fields.slug}>
                    Keep Reading →
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div ref={loadRef}>{hasMore ? <p>Loading...</p> : null}</div>
    </Fragment>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
