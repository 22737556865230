import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import BlogRoll from '../components/BlogRoll';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const BlogPage = () => (
  <Layout>
    <SEO title="Gust Blog" description="Gust Blog" isBlog={true} />
    <Header />
    <section id="hero___wrap" className="hero___wrap d-flex align-items-center">
      <div className="d-flex align-items-center">
        <div className="overlay"></div>
        <div className="container">
          <div className="slider-text">
            <div className="col-md-12">
              <h1>Gust Blog</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="blog" className="blog">
      <div className="section-content">
        <div className="container">
          <BlogRoll />
        </div>
      </div>
    </section>
    <Footer />
  </Layout>
);

export default BlogPage;
